import { PuckSize } from '../../../lib/constants';

const getMargin = (puckSize) => {
  switch (puckSize) {
    case PuckSize.XXX_SMALL:
      return '0.7rem';
    case PuckSize.XX_SMALL:
      return '0.9rem';
    case PuckSize.X_SMALL:
      return '1.0rem';
    case PuckSize.SMALL:
      return '1.3rem';
    case PuckSize.MEDIUM:
      return '1.6rem';
    case PuckSize.LARGE:
      return '1.8rem';
    case PuckSize.X_LARGE:
      return '2rem';
    default:
      return '1.6rem';
  }
};

export { getMargin };
