import { PuckType } from '../../../../lib/constants';
import dayjs from 'dayjs';

/**
 * Finds the previous flight leg of the swapped flight legs.
 * @param {Array} swapFlightLegsList - List of swapped flight legs.
 * @param {Array} ganttConfigList - Configuration list for the Gantt chart.
 * @returns {Array} List of previous swap flight leg data.
 */
export const findPreviousFlightLeg = (swapFlightLegsList, ganttConfigList) => {
  // Find the previous flight leg of the swapped flight legs
  let previousSwapPuckData = [];
  const swappedAircraftlist = [
    ...new Set(swapFlightLegsList.map((swapFlightLeg) => swapFlightLeg.swapAircraftRegistration)),
  ];

  swappedAircraftlist?.forEach((aircraft) => {
    const swapLineOfFlight = ganttConfigList[aircraft];
    const earliestSwappedPuckForAircraft = swapFlightLegsList
      .filter((fl) => fl.swapAircraftRegistration === aircraft)
      .sort((a, b) =>
        dayjs(a.scheduledOperatingDateTimeUTC).isBefore(dayjs(b.scheduledOperatingDateTimeUTC)) ? -1 : 1,
      )[0];

    const swapFlightLegIndex = swapLineOfFlight?.scheduled?.findIndex(
      (puck) =>
        (puck.puckType === PuckType.FLIGHT || puck.puckType === PuckType.GROUND_STANDBY) &&
        puck.flightPuckData?.flightLegKey === earliestSwappedPuckForAircraft?.flightLegKey,
    );

    if (swapFlightLegIndex > 0) {
      const previousPuckData = { ...swapLineOfFlight.scheduled[swapFlightLegIndex - 1] };
      // Add the previous swap flight leg data to the list
      if (previousPuckData?.flightPuckData) {
        previousSwapPuckData.push(previousPuckData.flightPuckData);
      }
    }
  });
  return previousSwapPuckData;
};
