import { combineReducers } from 'redux';
import filterReducer from './filterReducer';
import selectedFlightDetailsReducer from './selectedFlightDetailsReducer';
import autoRefreshReducer from './autoRefreshReducer';
import hideCancelledFlightsReducer from './hideCancelledFlightsReducer';
import swapModeReducer from './swapModeReducer';
import dragDropReducer from './dragDropReducer/dragDropReducer';
import configReducer from './configReducer';
import crosshairReducer from './crosshairReducer';
import flightPuckRefReducer from './flightPuckRefReducer/flightPuckRefReducer';
import multiSelectedFlightGroupReducer from './multiSelectedFlightGroupReducer';
import summaryPanelReducer from './summaryPanelReducer';
import focusedFlightLegReducer from './focusedFlightLegReducer';
import searchFlightReducer from './searchFlightReducer';
import viewIdReducer from './viewIdReducer';
import { RootAction } from '../actionTypes';
import viewConfigurationReducer from './viewConfigurationReducer';
import sortReducer from './sortReducer';

/** Combine all the reducers */
const allReducers = combineReducers({
  id: viewIdReducer,
  filterReducer,
  selectedFlightDetailsReducer,
  autoRefreshReducer,
  hideCancelledFlightsReducer,
  swapModeReducer,
  dragDropReducer,
  crosshairReducer,
  configReducer,
  flightPuckRefReducer,
  multiSelectedFlightGroupReducer,
  summaryPanelReducer,
  focusedFlightLegReducer,
  searchFlightReducer,
  viewConfigurationReducer,
  sortReducer,
});

/** Memoize the default state of all reducers 
 you’re effectively simulating the initial creation of the Redux store. 
 This line of code will return the initial state of the entire store,
 which is a combination of the default states of all your reducers.*/
const defaultState = allReducers(undefined, { type: 'INIT' });

/**
 * Root Reducer for the application state management
 * @param {Object} state - The current state of the application
 * @param {Object} action - The action dispatched
 * @returns {Object} The new state of the application
 */
const rootReducer = (state, action) => {
  const { type, payload } = action;
  if (type === RootAction.UPDATE_ROOTSTATE) {
    return { ...defaultState, id: state.id, ...payload.rootState };
  } else {
    return allReducers(state, action);
  }
};

export default rootReducer;
