import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { ReactComponent as ConfigurationFileIcon } from '../../assets/images/configuration-file-icon.svg';
import { ReactComponent as ConfigurationTrashIcon } from '../../assets/images/configuration-trash-icon.svg';
import SaveConfigModal from './SaveConfigModal/SaveConfigModal';
import ConfirmationConfigModal from './ConfirmationConfigModal/ConfirmationConfigModal';
import { useConfigStore, useConfigDispatch } from '../../hooks/useConfigStore/useConfigStore';
import { useFetchUserConfigurations } from '../../hooks/useUserConfigurationQuery/useUserConfigurationQuery';
import { useMutation, useQueryClient } from 'react-query';
import { useRootDispatch } from '../../hooks/useConfigStore/useRootStore';
import { useMultipleViewsRootDispatch } from '../../hooks/useConfigStore/useMultipleViewsRootStore';
import { clearComponentScrollState } from '../../lib/utils';
import {
  getUserConfiguration,
  saveUserConfiguration,
  saveAsUserConfiguration,
  deleteUserConfiguration,
} from '../../services/apiClient/userConfigurationsApi/userConfigurationsApi';
import { utcToStationLocalTime } from '../../lib/dateTimeUtils';
import { useRefetchPageData } from '../../hooks/useRefetchQuery/useRefetchQuery';
import { TimeZones, RefetchPageData, Treatment, FlightView, ScrollLocalStorageElementId } from '../../lib/constants';
import ConfigurationMapper from './ConfigurationMapper';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import PropTypes from 'prop-types';
import Modal from '../Shared/Modal/Modal';
import exclamationPoint from '../../assets/images/exclamation-point.png';
import './Configuration.css';

/**
 * Configuration Page component
 * @param {*} children
 * @returns
 */
const ConfigurationPage = ({ children }) => {
  return (
    <div data-cy="configuration-page" className="configuration">
      <div className="page-header">
        <div data-cy="configuration-header-text">Saved Configuration Files</div>
      </div>
      {children}
    </div>
  );
};

/**
 * Configuration Icon component
 * @returns Configuration Icon
 */
const ConfigurationIcon = () => {
  return <ConfigurationFileIcon data-cy="configuration-file-icon" className="configuration-file-icon" />;
};

const ConfigInputNameStatus = {
  NEW_CONFIG_NAME: 'NEW_CONFIG_NAME',
  ACTIVE_CONFIG_NAME: 'LOADED_CONFIG_NAME',
  EXISTING_ON_OTHER_CONFIG: 'EXISTING_ON_OTHER_CONFIG',
};

const CONFIRMATION_MODAL_TYPE = {
  CONFIRM_SAVE_AS: 'CONFIRM_SAVE_AS',
  CONFIRM_DELETE: 'CONFIRM_DELETE',
};

const initSavableConfigDetails = {
  title: '',
  text: ``,
  secondLineText: '',
  type: '',
  yesButtonText: '',
  noButtonText: '',
  deleteConfirmationId: -1,
};

const Configuration = () => {
  const { openSaveConfig, activeConfigurationId } = useConfigStore();

  // feature flag hooks
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const history = useHistory();

  const { openSaveConfigModal, closeSaveConfigModal } = useConfigDispatch();
  const {
    MapSingleViewState_ToUserConfig,
    MapMultiViewState_ToUserConfig,
    MapUserConfig_ToSingleViewState,
    MapUserConfig_ToMultipleViewState,
  } = ConfigurationMapper();
  ConfigurationMapper();

  // Update root state to be used to Load a configuration for SingleView (when Multiple Views feature is disabled) and MultipleViews (when Multiple Views feature is enabled)
  const { updateRootState } = useRootDispatch();
  const { updateMultipleViewsRootState } = useMultipleViewsRootDispatch();

  var queryClient = useQueryClient();

  const saveErrorMessage = 'Failed to save user configuration, please try again';
  const loadConfigWithId = useMutation({
    mutationFn: (configId) => getUserConfiguration(configId),
    onSuccess: (userConfig) => {
      if (userConfig) {
        if (!showMultipleViews) {
          // Load configuration when Multiple Views feature is disabled
          const singleViewState = MapUserConfig_ToSingleViewState(userConfig);
          updateRootState(singleViewState);
          if (singleViewState.configReducer.singleViewType.toString() === FlightView.GANTT_VIEW.toString()) {
            clearComponentScrollState([ScrollLocalStorageElementId.GANTT_CHART]);
            history.push('/flights?view=gantt');
          } else {
            clearComponentScrollState([ScrollLocalStorageElementId.GANTT_CHART]);
            history.push('/flights?view=table');
          }
        } else {
          // Load configuration when Multiple Views feature is enabled
          const multiViewState = MapUserConfig_ToMultipleViewState(userConfig);
          updateMultipleViewsRootState(multiViewState);
          clearComponentScrollState([
            ScrollLocalStorageElementId.GANTT_CHART,
            ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
          ]);
          history.push('/flights');
        }
      }
    },
  });

  const saveNewConfig = useMutation({
    mutationFn: (configData) => saveUserConfiguration(configData),
    onSuccess: () => {
      queryClient.invalidateQueries('userconfigurations');
      closeSaveConfigModal();
    },
    onError: () => {
      setSaveErrorToastState({ show: true, message: saveErrorMessage });
    },
  });
  const saveAsConfig = useMutation({
    mutationFn: (configData) => saveAsUserConfiguration(configData),
    onSuccess: () => {
      queryClient.invalidateQueries('userconfigurations');
      closeSaveConfigModal();
    },
    onError: () => {
      openSaveConfigModal();
      setSaveErrorToastState({ show: true, message: saveErrorMessage });
    },
  });
  const deleteConfig = useMutation({
    mutationFn: (configData) => deleteUserConfiguration(configData),
    onSuccess: () => {
      queryClient.invalidateQueries('userconfigurations');
    },
    onError: () => {
      setErrorPopupState({ show: true, message: 'Failed to delete user configuration, please try again' });
    },
  });

  const {
    isLoading: isLoadingList,
    isError: hasListError,
    userConfigList,
    statusCode: userConfigListStatus,
  } = useFetchUserConfigurations();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState(initSavableConfigDetails);
  const [configNameToSave, setConfigNameToSave] = useState('');

  const defaultErrorPopupState = {
    show: false,
    message: '',
  };
  const [errorPopupState, setErrorPopupState] = useState(defaultErrorPopupState);
  const [saveErrorToastState, setSaveErrorToastState] = useState(defaultErrorPopupState);

  const { refetchData } = useRefetchPageData();

  function handleHideErrorPopup() {
    setErrorPopupState(defaultErrorPopupState);
  }

  function handleHideErrorToast() {
    setSaveErrorToastState(defaultErrorPopupState);
  }

  useEffect(() => {
    if (userConfigListStatus !== 200 && hasListError && !openSaveConfig) {
      const defaultErrorMessage = 'Failed to retrieve user configurations, please try again';
      const errorMessages = {
        500: 'Failed to retrieve user configurations, please try again',
        404: 'Failed to retrieve selected configuration, please try again',
      };
      setErrorPopupState({
        show: true,
        message: errorMessages[userConfigListStatus] || defaultErrorMessage,
      });
    }
  }, [userConfigListStatus, hasListError]);

  useEffect(() => {
    if (isLoadingList) {
      return;
    }
    if (activeConfigurationId && configNameToSave.length === 0) {
      // If UserConfigurationsList is loaded successfully, set the config name to save as the active configuration name

      const existingElement = userConfigList.find((config) => config.configurationId === activeConfigurationId);
      if (existingElement) {
        setConfigNameToSave(existingElement.configurationName);
      }
    }
  }, [isLoadingList]);

  const errorHtml = (
    <Fragment>
      <div className="error-popup-modal">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div>
              <div className={`error-icon error-alert`}>
                <img src={exclamationPoint} alt="toast" />
              </div>
              {errorPopupState.message}
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  if (isLoadingList) {
    return <ConfigurationPage>Loading...</ConfigurationPage>;
  }

  const configNameAvailabilityStatus = (existingElement) => {
    if (existingElement) {
      if (activeConfigurationId === existingElement.configurationId) {
        return ConfigInputNameStatus.ACTIVE_CONFIG_NAME;
      } else {
        return ConfigInputNameStatus.EXISTING_ON_OTHER_CONFIG;
      }
    } else {
      return ConfigInputNameStatus.NEW_CONFIG_NAME;
    }
  };

  const handleDeleteConfig = (configurationId) => {
    deleteConfig.mutate(configurationId);
  };

  const handleDeleteConfirm = (id) => {
    const confirmationModalDetails = {
      title: 'Confirm Delete',
      text: 'Deleting the config file cannot be undone. all data within config will be lost',
      secondLineText: '',
      type: CONFIRMATION_MODAL_TYPE.CONFIRM_DELETE,
      yesButtonText: 'Cancel',
      noButtonText: 'Delete',
      deleteConfirmationId: id,
    };
    handleConfirmationModal(true, confirmationModalDetails);
  };

  const handleApplyConfig = (id) => {
    loadConfigWithId.mutate(id);
  };

  /**
   * @description handling closing add flight modal.
   */
  const handleModalCancel = () => {
    closeSaveConfigModal();
    setConfigNameToSave('');
  };

  const makeSaveConfigApiCall = async () => {
    const existingElement = userConfigList.find((config) => config.configurationName === configNameToSave);
    const existingElementConfigId = existingElement ? existingElement.configurationId : -1;
    const newConfig = {
      configurationName: configNameToSave,
      configurationDocument: showMultipleViews ? MapMultiViewState_ToUserConfig() : MapSingleViewState_ToUserConfig(),
    };
    switch (existingElementConfigId) {
      case -1:
        saveNewConfig.mutate(newConfig);
        break;
      default:
        saveAsConfig.mutate({ configurationId: existingElementConfigId, configuration: newConfig });
        break;
    }
  };

  const handleModalSave = async () => {
    refetchData(RefetchPageData.USER_CONFIGURATION_PAGE, true);
    await sleep(200);

    const existingElement = userConfigList.find((config) => config.configurationName === configNameToSave);
    switch (configNameAvailabilityStatus(existingElement)) {
      case ConfigInputNameStatus.NEW_CONFIG_NAME:
        makeSaveConfigApiCall();
        handleConfirmationModal(false, initSavableConfigDetails);
        break;
      case ConfigInputNameStatus.ACTIVE_CONFIG_NAME:
        makeSaveConfigApiCall();
        break;
      case ConfigInputNameStatus.EXISTING_ON_OTHER_CONFIG:
        closeSaveConfigModal();
        const confirmationModalDetails = {
          title: 'Confirm Save As',
          text: `${configNameToSave} already exists.`,
          secondLineText: 'Do you want to replace it?',
          type: CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS,
          yesButtonText: 'Yes',
          noButtonText: 'No',
        };
        handleConfirmationModal(true, confirmationModalDetails);
        break;
      default:
        break;
    }
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleConfirmationModal = (openConfirmationModal, confirmationModalDetails) => {
    setOpenConfirmationModal(openConfirmationModal);
    setConfirmationModalDetails(confirmationModalDetails);
  };

  const handleConfirmationYes = () => {
    handleConfirmationModal(false, initSavableConfigDetails);
    if (confirmationModalDetails.type === CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS) {
      //if YES is coming from Save As confirmation modal, Save As the config with same name.
      makeSaveConfigApiCall();
    }
  };
  const handleConfirmationNo = () => {
    //if NO is coming from Delete confirmation modal, delete the config.
    if (confirmationModalDetails.type === CONFIRMATION_MODAL_TYPE.CONFIRM_DELETE) {
      handleDeleteConfig(confirmationModalDetails.deleteConfirmationId);
    }
    handleConfirmationModal(false, initSavableConfigDetails);
    // if NO is coming from Save As confirmation modal, open Save Config Modal.
    if (confirmationModalDetails.type === CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS) {
      openSaveConfigModal();
    }
  };

  const onInputChangeHandler = (inputText) => {
    setConfigNameToSave(inputText);
  };

  // transform functions for user configurations
  function* transformConfigs(data) {
    if (!data) {
      return null;
    }

    for (const config of data) {
      yield transformConfig(config);
    }
  }
  function transformConfig(config) {
    if (!config) {
      throw new Error('User Configuration is null.');
    }
    const dateUTC = config.modifiedDateUTC || config.createdDateUTC;
    const dateModified = utcToStationLocalTime(dateUTC, TimeZones.PDT, 'YYYY-MM-DD');
    const timeModified = utcToStationLocalTime(dateUTC, TimeZones.PDT, 'HH:mm');
    return {
      ...config,
      dateModified,
      timeModified,
    };
  }

  return (
    <ConfigurationPage>
      <TableContainer
        className="configuration-table-container"
        data-cy="configuration-table-container"
        component={Paper}
      >
        <Table className="configuration-table">
          <TableHead className="equipment-change-table-head">
            <TableRow>
              <TableCell key="table-header-icon" data-cy="table-header-icon">
                {' '}
              </TableCell>
              <TableCell key="table-header-filename" data-cy="table-header-filename" className="th-table-header-cell">
                File Name
              </TableCell>
              <TableCell key="table-header-date" data-cy="table-header-date" className="th-table-header-cell">
                Date Modified
              </TableCell>
              <TableCell
                key="table-header-modifiedby"
                data-cy="table-header-modifiedby"
                className="th-table-header-cell"
              >
                Modified By
              </TableCell>
              <TableCell key="table-header-delete" data-cy="table-header-delete">
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy="configuration-table-body" className="configuration-table-body">
            {Array.from(transformConfigs(userConfigList)).map((config, i) => (
              <TableRow
                key={`table-row-${config.configurationId}`}
                style={{
                  mixBlendMode: config.configurationId === activeConfigurationId ? 'color-dodge' : 'normal',
                }}
              >
                <TableCell key={`row-cell-icon-${config.configurationId}`} className={`td-icon`}>
                  {ConfigurationIcon()}
                </TableCell>
                <TableCell
                  key={`row-cell-file-${config.configurationId}`}
                  className={`config-file td-${config.configurationName}`}
                  data-cy="configuration-configuration-name"
                  onClick={() => handleApplyConfig(config.configurationId)}
                >
                  {config.configurationName}
                </TableCell>
                <TableCell key={`row-cell-date-${config.configurationId}`} className={`td-${config.dateModified}`}>
                  <span>{config.dateModified}</span> <span className="modified-time">{config.timeModified}</span>
                </TableCell>
                <TableCell key={`row-cell-by-${config.configurationId}`} className={`td-${config.userName}`}>
                  {config.userName}
                </TableCell>
                <TableCell key={`row-cell-delete-${config.configurationId}`} className={`td-delete`}>
                  <ConfigurationTrashIcon
                    onClick={() => handleDeleteConfirm(config.configurationId)}
                    data-cy="configuration-trash-icon"
                    className="config-trash-icon"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openSaveConfig && (
        <SaveConfigModal
          onClose={handleModalCancel}
          showModal={openSaveConfig}
          onSave={handleModalSave}
          onInputChange={onInputChangeHandler}
          configNameToSave={configNameToSave}
          handleHideErrorToast={handleHideErrorToast}
          saveErrorToastState={saveErrorToastState}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationConfigModal
          title={confirmationModalDetails?.title}
          confirmationText={confirmationModalDetails?.text}
          secondLineText={confirmationModalDetails?.secondLineText}
          yesButtonText={confirmationModalDetails?.yesButtonText}
          noButtonText={confirmationModalDetails?.noButtonText}
          onYes={handleConfirmationYes}
          onNo={handleConfirmationNo}
          onModalClose={
            confirmationModalDetails?.type === CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS
              ? handleConfirmationNo
              : handleConfirmationYes
          }
          showModal={openConfirmationModal}
        />
      )}
      <Modal
        show={errorPopupState.show}
        title={'Error'}
        body={errorHtml}
        onHide={handleHideErrorPopup}
        customCSSTag="error-popup"
        footerButtons={[]}
        size="thin"
        bottomLine={false}
      />
    </ConfigurationPage>
  );
};

ConfigurationPage.propTypes = {
  children: PropTypes.object.isRequired,
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Configuration);
