import React, { Fragment, useState, useMemo } from 'react';
import { withAppInsightsTracking } from '../../../../services/appInsightsFactory/appInsightsFactory';
import Modal from '../../../Shared/Modal/Modal';
import Button from '../../../Shared/Button/Button';
import { Grid } from '@material-ui/core';
import BrickContainer from '../../../Shared/BrickContainer/BrickContainer';
import IrropsReasons from '../../../Shared/IrropsReasons/IrropsReasons';
import TextAreaInput from '../../../Shared/Inputs/TextAreaInput/TextAreaInput';
import { focusNextElement, refetchDataRule } from '../../../../lib/utils';
import { EntitlementNames, KeyCodes, RefetchPageData } from '../../../../lib/constants';

import { makeStyles } from '@material-ui/core/styles';
import './SwapConfirmationModal.css';
import { useSwapModeDispatch, useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { useSummaryPanelStore } from '../../../../hooks/useSummaryDataStore/useSummaryDataStore';
import { useAppInsightsContext } from '../../../../contexts/AppInsightsContext/AppInsightsContext';
import { useNotificationUpdate } from '../../../../contexts/NotificationsContext/NotificationsContext';
import { swapsApi } from '../../../../services/apiClient/swapsApi/swapsApi';
import { ToastType } from '../../../Shared/NotificationToast/NotificationToast';
import useAuthorizationAccess from '../../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { useUserContext } from '../../../../contexts/UserContext/UserContext';
import { isNullOrWhitespace } from '../../../../lib/displayUtils';
import { useRoleAssignmentContext } from '../../../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { findFlightLegs } from '../../../../services/apiClient/flightsApi/flightsApi';
import { useRefetchPageData } from '../../../../hooks/useRefetchQuery/useRefetchQuery';
import { useSwapReasonsQuery } from '../../../../hooks/useSwapReasonsQuery/useSwapReasonsQuery';
import { useMultiSelectedFlightGroupDispatch } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeader: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  brickLabel: {
    fontSize: 'var(--defaultFontSize)',
    fontWeight: 'bold',
    marginBottom: '0.218rem',
  },
  HeaderGrid: {
    paddingBottom: '.6rem !important',
    paddingTop: '1rem !important',
  },
  ReasonContainer: {
    paddingTop: '3.3rem !important',
  },
  brickLabel2: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: '0.25rem',
  },
}));

/**
 * The SwapConfirmationModal component render the swap confirmation modal
 * It provides the Reaons and Comment fields and Swap Submit button,
 * @param {func} onClose - function to handle closiing the modal
 * @returns SwapMode footer
 */

const SwapConfirmationModal = ({ onClose }) => {
  const selectedReasonPlaceholder = '';
  const classes = useStyles();
  // useSwapModeDispatch hook
  const { clearSwapData, savedSwapFlightLegs } = useSwapModeDispatch();
  // useSwapModeStore redux store
  const { isSwapModeActive, swapFlightLegs } = useSwapModeStore();
  //SummaryPanelStore redux store
  const { panelMode } = useSummaryPanelStore();

  // Authorization access hook
  const hasSwapEntitlement = useAuthorizationAccess([], [EntitlementNames.SWAP]);
  // useAppInsightsContext hook
  const { trackEvent, logInformation } = useAppInsightsContext();
  //  useNotificationUpdate hook
  const notificationUpdate = useNotificationUpdate();
  // use States
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [isOpenList, setIsOpenList] = useState(false);
  const [userComments, setUserComments] = useState('');
  const [selectedReason, setSelectedReason] = useState({
    text: selectedReasonPlaceholder,
    swapKey: null,
    cannedComment: '',
    isCommentsRequired: false,
  });

  const { state: userContextState } = useUserContext();
  const { roleAssignments } = useRoleAssignmentContext();
  const operatingAirlinesCode = roleAssignments.role.operatingAirline;
  const { refetchData, refetchDataFor } = useRefetchPageData();
  const { data: swapReasonsData } = useSwapReasonsQuery(operatingAirlinesCode);
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();

  /**
   * @description Handle the on selecting the reason dropdown and set the state.
   * @param {string} reason Selected reason value.
   * @param {any} reasonKey Reason key.
   * @param {string} commentsTemplate User comments.
   * @param {boolean} commentRequired Boolean value for checking comments required.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentRequired, event, target) => {
    setSelectedReason({
      text: reason,
      swapKey: reasonKey,
      cannedComment: commentsTemplate,
      isCommentsRequired: commentRequired,
    });
    focusNextElement(event, target);
    setIsOpenList(false);
  };

  /**
   * @description Handle the text input changes for user comments
   */
  const handleTextInputChange = (value) => {
    setUserComments(value);
  };

  /**
   * @description Get the Swap Reasons data using an api call
   * @returns an array of SwapReasons
   */

  const swapReasonsList = useMemo(() => {
    let result = [];
    if (isNullOrWhitespace(operatingAirlinesCode)) {
      return;
    }

    if (
      swapReasonsData != null &&
      Array.isArray(swapReasonsData.swapReasons) &&
      swapReasonsData.swapReasons.length > 0
    ) {
      result = swapReasonsData.swapReasons.map((item) => {
        return { value: item, name: item, commentsTemplate: 'Describe details', isCommentsRequired: true };
      });
    }
    return result;
  }, [operatingAirlinesCode, swapReasonsData]);

  /**
   * @description Hitting Enter on Commit button triggers commit
   */
  const handleKeypress = (e) => {
    //It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && isCommitEnabled()) {
      handleCommitButton();
    }
  };

  /**
   * @description Hitting tab on submit button set focus on reason dropdown.
   */
  const handleOnBlur = (e) => {
    document.getElementById('irrops-code-reason').focus();
    setIsOpenList(true);
  };

  /**
   * @description Checks or handles commit button disable or enable logic.
   * @return true or false
   */
  const isCommitEnabled = () => {
    return (
      hasSwapEntitlement &&
      isSwapModeActive &&
      swapFlightLegs.length &&
      selectedReason.isCommentsRequired &&
      !!userComments &&
      !commitButtonClicked
    );
  };

  const handleSuccessfulSwapFindFlightLegsResponse = () => {
    notificationUpdate(ToastType.SUCCESS, '', false, false); // Hide notification
    logInformation(
      `Received Flights API Response. All expected swaps found`,
      'SwapConfirmationModal',
      'handleCommitButton',
    );
    removeAllMultiSelectedFlightGroup();
    // Refetch the data after successful swap
    refetchData(RefetchPageData.GANTT, true);
    refetchData(RefetchPageData.FLIGHT_DETAIL_PANE_ACTIVITY_LOG, true);
    // Refetch some queries based on fetching rules for the page.
    refetchDataFor(refetchDataRule(false, panelMode)); // panelMode is SummaryPanelMode
  };

  const convertSwapFlightLegToFindFlightLegsPayload = (leg) => {
    const {
      flightNumber,
      scheduledOperatingDateUTC,
      actualOrigin,
      actualDestination,
      departureCount,
      operatingAirline,
      swapAircraftRegistration,
    } = leg;

    return {
      scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      origin: actualOrigin,
      destination: actualDestination,
      operatingAirline: operatingAirline,
      departureCount: departureCount,
      flightNumber: flightNumber.toString(),
      findFlightLegCriteria: {
        aircraftRegistration: swapAircraftRegistration,
      },
    };
  };

  const handleCommitButton = async () => {
    setCommitButtonClicked(true);

    const uiPayloadData = createPayloadData(swapFlightLegs);
    const swapsApiResult = await swapsApi(uiPayloadData);
    trackEvent(`Swaps - API call to submit swap payloads. Status:${swapsApiResult?.status}`);

    if (swapsApiResult?.status === 'SUCCESS') {
      clearSwapData({ disableSwapSubmit: true });
      await handleSuccessfulSwap(swapsApiResult);
    } else {
      handleFailedSwap();
    }
  };

  const handleSuccessfulSwap = async (swapsApiResult) => {
    onClose();
    showToast(ToastType.SUCCESS, 'Success: Swap was submitted. Checking status...');
    logInformation(
      'Swap submitted successfully to Swaps Queue Publisher API. Checking Flights API status',
      'SwapConfirmationModal',
      'handleCommitButton',
    );

    const swapFlightLegsList = swapFlightLegs.map(convertSwapFlightLegToFindFlightLegsPayload);
    const findFlightLegsResponse = await findFlightLegs(swapFlightLegsList);

    await processFindFlightLegsResponse(findFlightLegsResponse || {});
  };

  const handleFailedSwap = () => {
    onClose();
    showToast(ToastType.ERROR, 'Failed to submit Swaps. Please try again');
  };

  const showToast = (type, message) => {
    notificationUpdate(type, message, true, false);
  };

  const processFindFlightLegsResponse = async (response) => {
    const { flightLegsFound, flightLegsNotFound } = response;

    if (flightLegsNotFound?.length === 0) {
      handleSuccessfulSwapFindFlightLegsResponse();
    } else if (flightLegsNotFound?.length > 0) {
      await handlePartialSwapFindFlightLegsResponse(flightLegsFound, flightLegsNotFound);
    } else {
      handleFailedFindFlightLegsResponse();
    }
  };

  const handlePartialSwapFindFlightLegsResponse = async (foundLegs, notFoundLegs) => {
    const secondFindFlightLegsResponse = await findFlightLegs(notFoundLegs);
    const { flightLegsFound, flightLegsNotFound } = secondFindFlightLegsResponse;

    if (flightLegsNotFound?.length === 0) {
      handleSuccessfulSwapFindFlightLegsResponse();
    } else {
      handleFailedPartialSwapFindFlightLegsResponse([...foundLegs, ...flightLegsFound], flightLegsNotFound);
    }
  };

  const handleFailedFindFlightLegsResponse = () => {
    showToast(ToastType.ERROR, 'One or more swaps have failed to process. Please try again.');
    logInformation('Failed to receive Flights API Response.', 'SwapConfirmationModal', 'handleCommitButton');
  };

  const handleFailedPartialSwapFindFlightLegsResponse = (foundLegs, notFoundLegs) => {
    showToast(ToastType.ERROR, 'One or more swaps have failed to process. Please try again.');
    logInformation(
      `Received Flights API Response. Partial swaps not found: \n${JSON.stringify(notFoundLegs, null, 2)}`,
      'SwapConfirmationModal',
      'handleCommitButton',
    );
    savedSwapFlightLegs({ flightData: foundLegs });
  };

  /**
   * @description Create the API payload to be sent for the post API call from the UI swapstore flightleg data
   * @param {object} flightLegDataFromSwapStore - swaps flight leg data from the store
   * @returns {Object} API payload to be sent to the post request
   */
  const createPayloadData = (flightLegDataFromSwapStore) => {
    const uiPayloadData = flightLegDataFromSwapStore.map((item) => {
      const {
        actualDestination,
        actualOrigin,
        scheduledOrigin,
        scheduledDestination,
        swapAircraftRegistration,
        departureCount,
        flightNumber,
        operatingAirline,
        scheduledOperatingDateUTC,
        moveFromAircraft,
      } = item;
      return {
        operatingAirlineCode: operatingAirline,
        flightNumber: String(flightNumber),
        actualOrigin,
        actualDestination,
        scheduledOrigin,
        scheduledDestination,
        scheduledOperatingDateUTC,
        aircraftRegistration: swapAircraftRegistration,
        departureCount,
        swapReason: selectedReason.text,
        swapReasonComment: userComments,
        employeeID: userContextState.employeeID,
        moveFromAircraft,
      };
    });
    return uiPayloadData;
  };

  // formButtons
  const formHTML = (
    <Fragment>
      <div className="swap-flight-form-container" data-cy="swap-flight-form-container">
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.HeaderGrid}>
            <div className={classes.sectionHeader}>DOCUMENTATION</div>
          </Grid>
          <Grid container item md={6} sm={12} xs={12}>
            <Grid item xs={12}>
              <BrickContainer title="Reason" customCSSTag={classes.brickLabel2} isRequired={true}>
                <IrropsReasons
                  onSelect={handleOnSelectReason}
                  reasonsList={swapReasonsList}
                  airlineCode={operatingAirlinesCode}
                  irropsCode={'swapReason'}
                  selectedReason={selectedReason.text}
                  isAutoFocus={true}
                  isOpenList={isOpenList}
                  className="swap-reasons-menulist"
                  fetchReasonsApi={false}
                />
              </BrickContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.ReasonContainer}>
            <BrickContainer
              title={`Comment`}
              customCSSTag={classes.brickLabel}
              isRequired={selectedReason.isCommentsRequired}
            >
              <TextAreaInput
                value={userComments}
                placeholder={selectedReason.cannedComment}
                rows={4}
                onChange={handleTextInputChange}
                dataCyTag="swap-confirmation-comments-textarea"
              />
            </BrickContainer>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );

  // Footer button of Swap Confirmation Modal.
  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="swap-flight-form-commit-button"
      onClick={handleCommitButton}
      onKeyPress={handleKeypress}
      onBlur={handleOnBlur}
      isDisabled={!isCommitEnabled()}
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <Modal
      size={`responsive swap-confirmation-modal`}
      show={true}
      title="Swap Confirmation"
      body={formHTML}
      footerButtons={formButtons}
      onHide={onClose}
      customCSSTag="swap-confirmation-form"
      dataCyTag="swap-confirmation-form"
    />
  );
};

export default withAppInsightsTracking(SwapConfirmationModal);
