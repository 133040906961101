import { useDispatch } from 'react-redux';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
import { SwapModeActionKeys, FilterActions } from '../../redux/actionTypes';
import { useCallback } from 'react';

/**
 * Selector for the auto refresh store
 */
const selectSwapMode = (state) => state.swapModeReducer;

/**
 * Custom hook for retrieving the swap mode store
 * @returns selector hook for retrieving swap mode store
 */

export const useSwapModeStore = () => {
  return useSelector(selectSwapMode);
};

/**
 *Custom hook for dispatching actions to update swap mode flags
 */
export const useSwapModeDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to enable swap mode
   */
  const enableSwapMode = useCallback(() => {
    dispatch({ type: SwapModeActionKeys.ENABLE_SWAP_MODE });
    dispatch({ type: FilterActions.ENABLE_SWAP_MODE_FILTER });
  }, [dispatch]);

  const savedSwapFlightLegs = useCallback(
    (payload) => {
      dispatch({ type: SwapModeActionKeys.SAVED_SWAP_FLIGHT, payload });
    },
    [dispatch],
  );

  /**
   * Callback to update the swap flight line
   */
  const swapFlightLine = useCallback(
    (payload) => {
      dispatch({ type: SwapModeActionKeys.SWAP_FLIGHT_LINE, payload });
    },
    [dispatch],
  );

  /**
   * Callback to update the move flight leg
   */
  const updateMoveFlightLeg = useCallback(
    (payload) => {
      dispatch({
        type: SwapModeActionKeys.MOVE_FLIGHT,
        payload: { flightData: payload.flightData, aircraftRegistration: payload.aircraftRegistration },
      });
    },
    [dispatch],
  );

  /**
   * Callback to update the undo flight legs
   */
  const undoSwapFlightLegs = useCallback(() => {
    dispatch({ type: SwapModeActionKeys.UNDO_FLIGHT });
  }, [dispatch]);

  /**
   * Callback to update the redo flight legs
   */
  const redoSwapFlightLegs = useCallback(() => {
    dispatch({ type: SwapModeActionKeys.REDO_FLIGHT });
  }, [dispatch]);

  /**
   * Callback to discard the swap flight legs
   */
  const discardSwapFlightLegs = useCallback(() => {
    dispatch({
      type: SwapModeActionKeys.DISCARD_FLIGHT,
    });
  }, [dispatch]);
  /**
   * Callback to clear swap mode store
   */
  const clearSwapMode = useCallback(() => {
    dispatch({ type: SwapModeActionKeys.CLEAR_SWAP_MODE });
    dispatch({ type: FilterActions.CLEAR_SWAP_MODE_FILTER });
  }, [dispatch]);

  /**
   * Callback to clear swap data
   */
  const clearSwapData = useCallback(
    (payload) => {
      dispatch({ type: SwapModeActionKeys.CLEAR_SWAP_DATA, payload: { disableSwapSubmit: payload.disableSwapSubmit } });
    },
    [dispatch],
  );

  /**
   * Callback to remove flight leg
   */
  const removeFlightLeg = useCallback(
    (payload) => {
      dispatch({ type: SwapModeActionKeys.REMOVE_FLIGHT, payload: { flightData: payload.flightData } });
    },
    [dispatch],
  );

  return {
    enableSwapMode,
    swapFlightLine,
    savedSwapFlightLegs,
    updateMoveFlightLeg,
    clearSwapMode,
    undoSwapFlightLegs,
    redoSwapFlightLegs,
    discardSwapFlightLegs,
    clearSwapData,
    removeFlightLeg,
  };
};
