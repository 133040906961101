import { ConfigFileAction } from '../actionTypes';
import { FlightView, SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';

// initial state values

const initialState = {
  openSaveConfig: false,
  activeConfigurationId: null,
  singleViewType: FlightView.GANTT_VIEW,
};

const getInitialState = () => {
  const savedConfigState = getSessionStorageItem(SessionStorageKeys.USER_CONFIG);

  if (savedConfigState) {
    return savedConfigState;
  }

  return initialState;
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const configReducer = (state = getInitialState(), action) => {
  // graduate to SWITCH statement when there are more actiontypes available for this reducer
  switch (action.type) {
    case ConfigFileAction.UPDATE_SAVECONFIG:
      return {
        ...state,
        openSaveConfig: action.payload.openSaveConfig ?? initialState.openSaveConfig,
      };
    case ConfigFileAction.UPDATE_ACTIVE_CONFIGURATION_ID:
      return {
        ...state,
        activeConfigurationId: action.payload.activeConfigurationId ?? initialState.activeConfigurationId,
      };
    case ConfigFileAction.UPDATE_SINGLEVIEWTYPE:
      return {
        ...state,
        singleViewType: action.payload.singleViewType ?? initialState.singleViewType,
      };
    default:
      return state;
  }
};

export default configReducer;
