import React from 'react';
import './EarlyDelayIndicatorLine.css';
import { getPuckArrivalDepartureLength } from '../GanttChart/ganttHelpers';
import { datetimeIsBefore, getDatetimeUtcNowString } from '../../../lib/dateTimeUtils';
import { useSelectedItemStore } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useUserPreferencesData } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import { getMargin } from './EarlyDelayIndicatorLineUtils';
/**
 * EarlyDelayIndicatorLine component wraps pucks to add lines for early/late delays/arrivals
 * @param {any} children - child elements this component wraps
 * @param {data} data - flight leg data
 * @returns Child component wrapped in EarlyDelayIndicatorLine component
 */
const EarlyDelayIndicator = ({ children, data }) => {
  //Get puck's display statistics
  const puckArrDepLengths = getPuckArrivalDepartureLength(data);
  const { state: userPreferencesState } = useUserPreferencesData();
  const nowtime = getDatetimeUtcNowString();
  const arrtime = data.arrival;

  const selectedFlightDetails = useSelectedItemStore();

  const selectLabel = selectedFlightDetails.data?.flightLegKey == data.flightLegKey ? 'selected-puck' : '';

  /* flight puck departure indicators delay or early
   * returns departure indicator div  */
  const departureIndicator = (userPreferencesState) => {
    if (puckArrDepLengths.depIndicatorLength !== 0) {
      let className = puckArrDepLengths.depIndicatorLength > 0 ? 'delay' : 'early';
      // check current flight phase in for dark line
      if (datetimeIsBefore(arrtime, nowtime)) {
        className = `darkline-${className}`;
      }

      let width = Math.abs(puckArrDepLengths.depIndicatorLength);
      let bottom = getMargin(userPreferencesState?.puckSize?.puckSize);

      return (
        <div
          className={`departure-indicator ${className}`}
          data-cy={`departure-indicator-${data.flightLegKey}`}
          style={{ width: `calc(${width} * var(--time-scale-minute))`, marginBottom: bottom }}
        />
      );
    }
  };

  /* flight puck arrival indicators delay or early
   * returns arrival indicator div  */
  const arrivalIndicator = (userPreferencesState) => {
    if (puckArrDepLengths.arrIndicatorLength !== 0) {
      let className = puckArrDepLengths.arrIndicatorLength > 0 ? 'delay' : 'early';
      // check current flight phase in for dark line
      if (datetimeIsBefore(arrtime, nowtime)) {
        className = `darkline-${className}`;
      }

      let width = Math.abs(puckArrDepLengths.arrIndicatorLength);
      let top = getMargin(userPreferencesState?.puckSize?.puckSize);

      return (
        <div
          className={`arrival-indicator ${className} ${selectLabel}`}
          data-cy={`arrival-indicator-${data.flightLegKey}`}
          style={{ width: `calc(${width} * var(--time-scale-minute))`, marginTop: top }}
        />
      );
    }
  };

  return (
    <>
      {departureIndicator(userPreferencesState)}
      {children}
      {arrivalIndicator(userPreferencesState)}
    </>
  );
};

export default EarlyDelayIndicator;
