import { useDispatch, useSelector } from 'react-redux';
import { RootAction } from '../../redux/actionTypes';

/** Selector for the Root store  */
const selectRoot = (state) => state.rootReducer;

/**
 * Custom hook for retrieving the root store
 * @returns selector hook for retrieving root store
 */
export const useRootStore = () => {
  const rootStore = useSelector(selectRoot);
  return rootStore;
};

/**
 * Custom hook for dispatching actions to update root state
 */
export const useRootDispatch = () => {
  const dispatch = useDispatch();

  //Update the root state with the payload provided in the argument
  const updateRootState = (payload) => {
    dispatch({
      type: RootAction.UPDATE_ROOTSTATE,
      payload: { rootState: payload },
    });
  };

  return { updateRootState };
};
