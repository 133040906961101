import { ApiErrorName, PuckType, TimeZones, OutOfServiceState } from '../../../lib/constants';
import { utcToStationLocalTime } from '../../../lib/dateTimeUtils';
import dayjs from 'dayjs';

const getSummaryDetails = (summaryData) => {
  let isSeatCountErr = false;
  let isDispatchDeskErr = false;
  let isGateDataErr = false;
  summaryData.errors.forEach((err) => {
    switch (err?.errorName) {
      case ApiErrorName.SEAT_COUNT_DATA:
        isSeatCountErr = true;
        break;
      case ApiErrorName.DISPATCH_DESK_DATA:
        isDispatchDeskErr = true;
        break;
      case ApiErrorName.GATE_DATA:
        isGateDataErr = true;
        break;
      default:
        break;
    }
  });
  let details = {
    flightNumber: summaryData.flightSummary.flightNumber ?? '-',
    irropsCode: summaryData.flightSummary.irregularOperationCode ?? '- -',
    origin: summaryData.flightSummary.origin ?? '-',
    destination: summaryData.flightSummary.destination ?? '-',
    timeDeparture:
      summaryData.flightSummary.departureTimeUTC == null
        ? '-'
        : dayjs.utc(summaryData.flightSummary.departureTimeUTC).tz(TimeZones.PDT).format('HH:mm'),
    timeDepartureTz: dayjs.utc(summaryData.flightSummary.departureTimeUTC).tz(TimeZones.PDT).format('z'),
    dateDeparture:
      summaryData.flightSummary.departureTimeUTC == null
        ? '-'
        : utcToStationLocalTime(summaryData.flightSummary.departureTimeUTC, TimeZones.PDT, 'MM/DD'),
    timeArrival:
      summaryData.flightSummary.arrivalTimeUTC == null
        ? '-'
        : dayjs.utc(summaryData.flightSummary.arrivalTimeUTC).tz(TimeZones.PDT).format('HH:mm'),
    timeArrivalTz: dayjs.utc(summaryData.flightSummary.arrivalTimeUTC).tz(TimeZones.PDT).format('z'),
    dateArrival:
      summaryData.flightSummary.arrivalTimeUTC == null
        ? '-'
        : utcToStationLocalTime(summaryData.flightSummary.arrivalTimeUTC, TimeZones.PDT, 'MM/DD'),
    departureGate: isGateDataErr ? 'Err' : summaryData.flightSummary.departureGate ?? '-',
    arrivalGate: isGateDataErr ? 'Err' : summaryData.flightSummary.arrivalGate ?? '-',
    passengerCount: isSeatCountErr ? 'Err' : summaryData.flightSummary.passengerCount ?? '-',
    dispatchDesk: isDispatchDeskErr ? 'Err' : summaryData.flightSummary.dispatchDesk ?? '-',
    bookedRevenuePassengerCount: isSeatCountErr ? 'Err' : summaryData.flightSummary.bookedRevenuePassengerCount ?? '-',
    aircraftSeatConfigurationCount: isSeatCountErr
      ? 'Err'
      : summaryData.flightSummary.aircraftSeatConfigurationCount ?? '-',
    ticketSalesAuthorizationCount: isSeatCountErr
      ? 'Err'
      : summaryData.flightSummary.ticketSalesAuthorizationCount ?? '-',
  };
  return details;
};

const getFlightDetails = (selectedFlightDetails) => {
  return {
    flightNumber: 'STBY',
    irropsCode: '- -',
    origin: selectedFlightDetails?.data?.arrivalStation ?? '-',
    destination: selectedFlightDetails?.data?.departureStation ?? '-',
    timeDeparture:
      selectedFlightDetails?.data?.arrival == null
        ? '-'
        : dayjs.utc(selectedFlightDetails.data.arrival).tz(TimeZones.PDT).format('HH:mm'),
    timeDepartureTz: dayjs.utc(selectedFlightDetails?.data?.arrival).tz(TimeZones.PDT).format('z'),
    dateDeparture:
      selectedFlightDetails?.data?.arrival == null
        ? '-'
        : utcToStationLocalTime(selectedFlightDetails.data.arrival, TimeZones.PDT, 'MM/DD'),
    timeArrival:
      selectedFlightDetails?.data?.departure == null
        ? '-'
        : dayjs.utc(selectedFlightDetails.data.departure).tz(TimeZones.PDT).format('HH:mm'),
    timeArrivalTz: dayjs.utc(selectedFlightDetails?.data?.departure).tz(TimeZones.PDT).format('z'),
    dateArrival:
      selectedFlightDetails?.data?.departure == null
        ? '-'
        : utcToStationLocalTime(selectedFlightDetails?.data?.departure, TimeZones.PDT, 'MM/DD'),
    departureGate: '-',
    arrivalGate: '-',
    passengerCount: '-',
    dispatchDesk: '-',
    bookedRevenuePassengerCount: '-',
    aircraftSeatConfigurationCount: '-',
    ticketSalesAuthorizationCount: '-',
  };
};

const getGroundEventDetails = (groundEventData) => {
  /**
   * @description custom rules for displaying OTS puck's state
   */
  const getOTSState = (state) => {
    if (state === null) return '-';
    // if state of OTS puck is ETR Completed or Completed, then we show "ETR" in summary panel.
    if (state === OutOfServiceState.COMPLETED || state === OutOfServiceState.COMPLETED_ETR) {
      return OutOfServiceState.ESTIMATED_TIME_READY;
    }
    return state;
  };

  return {
    flightNumber: getOTSState(groundEventData.state),
    irropsCode: '- -',
    origin: groundEventData.groundEventStation ?? '-',
    destination: groundEventData.groundEventStation ?? '-',
    timeDeparture:
      groundEventData.start == null ? '-' : dayjs.utc(groundEventData.start).tz(TimeZones.PDT).format('HH:mm'),
    timeDepartureTz: dayjs.utc(groundEventData.start).tz(TimeZones.PDT).format('z'),
    dateDeparture:
      groundEventData.start == null ? '-' : utcToStationLocalTime(groundEventData.start, TimeZones.PDT, 'MM/DD'),
    timeArrival: groundEventData.end == null ? '-' : dayjs.utc(groundEventData.end).tz(TimeZones.PDT).format('HH:mm'),
    timeArrivalTz: dayjs.utc(groundEventData.end).tz(TimeZones.PDT).format('z'),
    dateArrival: groundEventData.end == null ? '-' : utcToStationLocalTime(groundEventData.end, TimeZones.PDT, 'MM/DD'),
    departureGate: '-',
    arrivalGate: '-',
    passengerCount: '-',
    dispatchDesk: '-',
    bookedRevenuePassengerCount: '-',
    aircraftSeatConfigurationCount: '-',
    ticketSalesAuthorizationCount: '-',
  };
};

export const getSummaryInfo = (selectedFlightDetails, groundEventData, summaryLoading, summaryData) => {
  const selectedFlightPuckType = selectedFlightDetails?.data?.puckType;
  if (
    (selectedFlightPuckType === PuckType.GROUND_OTS || selectedFlightPuckType === PuckType.GROUND_UNAVAILABLE) &&
    Object.keys(groundEventData).length > 0
  ) {
    return getGroundEventDetails(groundEventData);
  } else if (selectedFlightDetails?.data?.puckType === PuckType.GROUND_STANDBY) {
    return getFlightDetails(selectedFlightDetails);
  } else if (
    summaryLoading === false &&
    summaryData != null &&
    summaryData != undefined &&
    summaryData.flightSummary != null
  ) {
    return getSummaryDetails(summaryData);
  }
  return {};
};

export const reArrangeDeadheadsCrewData = (arr) => {
  if (!arr) {
    return { table1: [], table2: [] };
  }
  const table1 = arr.slice(0, 5).concat(arr.slice(10).filter((_, i) => i % 2 === 0));
  const table2 = arr.slice(5, 10).concat(arr.slice(10).filter((_, i) => i % 2 !== 0));
  return { table1, table2 };
};
