import { useDispatch } from 'react-redux';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';
import { useCallback } from 'react';

/**
 * Selector for the auto refresh store
 */
const sortFilter = (state) => state.sortReducer;

/**
 * Custom hook for retrieving the swap mode store
 * @returns selector hook for retrieving swap mode store
 */

export const useSortFilterStore = () => {
  return useSelector(sortFilter);
};

/**
 *Custom hook for dispatching actions to update swap mode flags
 */
export const useSortFilterDispatch = () => {
  const dispatch = useDispatch();

  /**
   * Callback to update the move flight leg
   */
  const updateSortFilter = useCallback(
    (sortAction) => {
      console.log('updateSortFilter', sortAction);
      dispatch({ type: sortAction });
    },
    [dispatch],
  );

  return {
    updateSortFilter,
  };
};
