import React, { useReducer, useRef, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { getComponentScroll, setComponentScroll } from '../../../../lib/utils';
import { useViewId } from '../../../../contexts/ViewIdContext/ViewIdContext';
import { TableListActions as ACTIONS } from '../../../../redux/actionTypes';
import { ScrollLocalStorageElementId } from '../../../../lib/constants';
import { flightListTableConfig } from '../../config/flightListTableConfig';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { useVirtualizer, defaultRangeExtractor } from '@tanstack/react-virtual';
import usePrevious from '../../../../hooks/usePrevious/usePrevious';
import { flightListTableReducer } from '../../../../redux/reducers/flightListTableReducer';
import {
  useFocusedFlightLegStore,
  useFocusedFlightLegDispatch,
} from '../../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { useFlightPuckRefStore } from '../../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
import '../FlightListTable/FlightListTable.css';

/**
 * The FlightListTableVirtualizer component renders a flight list in table view. It's built on top
 * of the Material UI Table component.
 *
 * It's data is driven by both the flight list that's passed as a prop to the component,
 * and the flightListTableConfig array.
 * @param {Object[]} flightData - a list of flight leg objects
 * @param {Object[]} savedSort - the sort to apply to the table
 * @param {onSortClick} onConfirm - function to handle storing the sort
 * @returns {FlightListTableVirtualizer} - the FlightListTableVirtualizer component
 */
const FlightListTableVirtualizer = ({
  flightData,
  savedSort,
  onSortClick,
  openDetailPane,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
}) => {
  const flightDataCopy = useMemo(() => {
    return flightData != null && typeof flightData !== 'undefined' ? [...flightData] : [];
  }, [flightData]);

  const [state, dispatch] = useReducer(flightListTableReducer, {
    orderBy: savedSort.orderBy,
    orderDirection: savedSort.orderDirection,
    rawFlightData: flightDataCopy,
    sortedFlightData: flightDataCopy,
    displayCount: flightDataCopy.length,
  });
  const tableContentRef = useRef(null);
  const scrollSetRef = useRef(false); // Track if scroll position has been set

  const selectedFlightDetails = useSelectedItemStore();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();
  const getAircraftKeyByIndex = useCallback((i) => flightDataCopy[i], [flightDataCopy]);
  const currentActiveViewId = useViewId();

  const prevScrollPositions = getComponentScroll(currentActiveViewId.id, ScrollLocalStorageElementId.FLIGHTLIST_TABLE);
  const [prevScrollTop, setPrevScrollTop] = useState(prevScrollPositions?.scrollTop || null);
  const visibleRangeRef = useRef({ startIndex: 0, endIndex: 0 });
  const prevRef = usePrevious(tableContentRef);
  const { focusedFlightLeg, isFocused } = useFocusedFlightLegStore();
  const { setIsFocused } = useFocusedFlightLegDispatch();

  const flightPuckRefToScroll = useFlightPuckRefStore(focusedFlightLeg?.flightLegKey);

  const rowVirtualizer = useVirtualizer({
    // The total number of items to virtualize
    count: flightDataCopy.length,
    // The scrolling container
    getScrollElement: () => tableContentRef.current,
    // Function, given an index, returns the estimated height or width of the item
    estimateSize: () => 44,
    // Function, given an index, returns a unique key
    getItemKey: getAircraftKeyByIndex,
    // Number of items to render above and below the visible area
    overscan: 25,
    // Initial scrollTop value
    initialOffset: prevScrollPositions?.scrollTop,
    // Returns the indexes of the visible rows
    rangeExtractor: useCallback((range) => {
      visibleRangeRef.current = { startIndex: range.startIndex, endIndex: range.endIndex };
      return defaultRangeExtractor(range);
    }, []),
  });

  const rows = rowVirtualizer?.getVirtualItems();
  const [paddingTop, paddingBottom] =
    rows.length > 0
      ? [
          Math.max(0, rows[0].start - rowVirtualizer.options.scrollMargin),
          Math.max(0, rowVirtualizer.getTotalSize() - rows[rows.length - 1].end),
        ]
      : [0, 0];

  useEffect(() => {
    // Perform some basic checking on the flightData
    // Make a shallow copy to leave the prop data alone.
    let flights = flightData != null && typeof flightData !== 'undefined' ? [...flightData] : [];
    // The flight data prop has changed
    dispatch({
      type: ACTIONS.RECEIVED_DISPLAY_DATA,
      payload: { flightData: flights },
    });
  }, [flightData]);

  useEffect(() => {
    if (!prevRef && tableContentRef?.current) {
      // The scrolling container ref may be null on first render,
      // so we need to remeasure the virtualizer once the ref has been
      // attached to the scrolling container
      rowVirtualizer.measure();
    }
  }, [tableContentRef, prevRef, rowVirtualizer]);

  const scrollElementIntoView = (element) => {
    element?.scrollIntoView({ inline: 'center', block: 'center', behavior: 'instant' });
  };

  useEffect(() => {
    // Mark scroll position as not set initially
    scrollSetRef.current = false;
    // Check if the component is not focused, there is a focused flight leg, and a reference to scroll to
    if (!isFocused && focusedFlightLeg && flightPuckRefToScroll) {
      // Scroll the element into view
      scrollElementIntoView(flightPuckRefToScroll);
      // Set the focused state to true to prevent further scrolling into view
      setIsFocused(true);
    }
  }, [isFocused, focusedFlightLeg, flightPuckRefToScroll, setIsFocused]);

  useEffect(() => {
    // Use setTimeout to ensure the scroll operation happens after the current call stack is cleared
    setTimeout(() => {
      // Check if there is a focused flight leg and the table content ref is available
      if (focusedFlightLeg?.flightLegKey) {
        const indexVal = state.sortedFlightData.findIndex(
          (flight) => flight?.flightLegKey === focusedFlightLeg?.flightLegKey,
        );
        // Check if the focused flight leg is found in the sorted flight data array and scroll to it
        if (indexVal !== -1) {
          rowVirtualizer.measure();
          // Scroll to the focused flight leg
          rowVirtualizer.scrollToIndex(indexVal, { align: 'start', smoothScroll: false });
          scrollSetRef.current = true; // Mark scroll position as set
        } else {
          // Scroll to the start of the visible range if the focused flight leg is not found
          rowVirtualizer.scrollToIndex(visibleRangeRef.current.startIndex);
        }
      }
      // Check if the scroll position has been set and the table content ref is available and the previous scroll position is available and the scroll position is not set
      if (tableContentRef?.current && prevScrollTop && !scrollSetRef.current) {
        tableContentRef.current.scrollTop = prevScrollTop;
      }
    }, 10);
  }, [flightData, tableContentRef, focusedFlightLeg, state.sortedFlightData, rowVirtualizer, visibleRangeRef]);

  // Handle a click on a row in the table to open the Flight Panel and show the details of the selected flight leg
  const handleClick = (flightLeg, col) => {
    //open Flight Panel when row is clicked
    if (selectedFlightDetails.data?.flightLegKey !== flightLeg.flightLegKey) {
      const newFlightDetails = {
        data: flightLeg,
        isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
        isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
      };
      updateSelectedFlightDetails(newFlightDetails);
    }
  };

  /**
   * @description Handle a sort request by the user clicking a column header.
   * @param {string} columnKey - The key name of the column to be sorted.
   * @param {boolean} retainSortFlag - Flag for retaining the current sorting direction.
   */
  const handleSort = (columnKey, retainSortFlag = false) => {
    const isSortedAscending = state.orderBy === columnKey && state.orderDirection === 'asc';
    let newOrderDirection = 'asc';

    // Change the sort direction if we aren't specifying that the sort should remain the same.
    if (!retainSortFlag) {
      newOrderDirection = isSortedAscending ? 'desc' : 'asc';
    }

    // Send the sort back to the parent to save in state
    onSortClick(columnKey, newOrderDirection);

    // notify reducer of the sort
    dispatch({
      type: ACTIONS.SORT_DATA,
      payload: {
        orderBy: columnKey,
        orderDirection: newOrderDirection,
      },
    });
  };

  /**
   * Callback to synchronous scroll of the gantt chart content and the timeline
   * @param {*} e
   */
  const handleScroll = useCallback((e) => {
    const scrollTop = e.target.scrollTop;
    if (tableContentRef?.current) {
      setTimeout(() => {
        setPrevScrollTop(scrollTop);
        setComponentScroll(
          currentActiveViewId,
          ScrollLocalStorageElementId.FLIGHTLIST_TABLE,
          scrollTop,
          e.target.scrollLeft,
        );
      }, 10);
    }
  }, []);

  const tableRows = () => {
    return rowVirtualizer?.getVirtualItems().map((virtualRow, index) => {
      const row = state.sortedFlightData[virtualRow.index];
      const tableRowClassName = selectedFlightDetails.data?.flightLegKey === row?.flightLegKey ? 'selected' : '';

      return typeof row !== 'undefined' && Object.keys(row).length > 0 ? (
        <TableRow
          key={row?.flightLegKey}
          ref={rowVirtualizer.measureElement}
          style={{
            transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
            height: `${virtualRow.size}px`,
            width: '100%',
          }}
          className={tableRowClassName}
          data-cy={`flight-list-table-row-${row?.flightLegKey}`}
        >
          {flightListTableConfig.map((col, index) => (
            <TableCell
              key={`${col.columnKey}-${index}`}
              className={`td-${col.columnKey}`}
              onClick={() => handleClick(row, col)}
            >
              {col.renderFn != null &&
                col.renderFn(row, isPaneOpen, openDetailPane, summaryPanelMode, handleChangeActivityKey)}
            </TableCell>
          ))}
        </TableRow>
      ) : (
        <></>
      );
    });
  };

  return (
    <TableContainer
      className="flight-list-table-container"
      data-cy="flight-list-table virtulizer"
      ref={tableContentRef}
      onScroll={handleScroll}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize() + 44}px`,
          position: 'relative',
        }}
      >
        <Table stickyHeader>
          <TableHead className="flight-list-table-head">
            <TableRow>
              {/* define table headers */}
              {flightListTableConfig.map((col) => (
                <TableCell
                  sortDirection={state.orderBy === col.columnKey ? state.order : false}
                  key={col.columnKey}
                  data-cy="flight-list-table-header"
                  className={`th-${col.columnKey}`}
                >
                  {/* Check if Sort label is shown or not */}
                  {col.allowSort ? (
                    <TableSortLabel
                      active={state.orderBy === col.columnKey}
                      direction={state.orderBy === col.columnKey ? state.orderDirection : 'asc'}
                      onClick={() => handleSort(col.columnKey)}
                      data-cy={
                        state.orderBy === col.columnKey
                          ? col.columnKey + '-sort-' + state.orderDirection
                          : col.columnKey + '-unsorted'
                      }
                    >
                      {col.columnDisplayName}
                    </TableSortLabel>
                  ) : (
                    <span>{col.columnDisplayName}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody data-cy="flight-list-table-body">
            {tableRows()}
            {paddingTop > 0 && (
              <TableRow>
                <TableCell colSpan={flightListTableConfig?.length} style={{ height: paddingTop }} />
              </TableRow>
            )}
            {paddingBottom > 0 && (
              <TableRow>
                <TableCell colSpan={flightListTableConfig?.length} style={{ height: paddingBottom }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};

FlightListTableVirtualizer.propTypes = {
  flightData: PropTypes.array,
};

export default FlightListTableVirtualizer;
