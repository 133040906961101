import { useDispatch, useSelector } from 'react-redux';
import { ConfigFileAction } from '../../redux/actionTypes';

/**
 * Selector for the Config store
 */
const selectConfigMode = (state) => state.configReducer;

/**
 * Custom hook for retrieving the cross hair store
 * @returns selector hook for retrieving crosshair store
 */
export const useConfigStore = () => {
  return useSelector(selectConfigMode);
};

/**
 * Custom hook for dispatching actions to update crosshair flags
 */

export const useConfigDispatch = () => {
  const dispatch = useDispatch();

  // callback to update the config store
  const updateConfig = (payload) => {
    dispatch({
      type: ConfigFileAction.UPDATE_SAVECONFIG,
      payload: { openSaveConfig: payload.openSaveConfig },
    });
  };

  // callback to update the SaveConfig modal to open
  const openSaveConfigModal = () => {
    dispatch({
      type: ConfigFileAction.UPDATE_SAVECONFIG,
      payload: { openSaveConfig: true },
    });
  };

  // callback to update the SaveConfig modal to closed
  const closeSaveConfigModal = () => {
    dispatch({
      type: ConfigFileAction.UPDATE_SAVECONFIG,
      payload: { openSaveConfig: false },
    });
  };

  const updateActiveConfigurationId = (activeConfigurationId) => {
    dispatch({
      type: ConfigFileAction.UPDATE_ACTIVE_CONFIGURATION_ID,
      payload: { activeConfigurationId: activeConfigurationId },
    });
  };

  const updateSingleViewType = (singleViewType) => {
    dispatch({
      type: ConfigFileAction.UPDATE_SINGLEVIEWTYPE,
      payload: { singleViewType: singleViewType },
    });
  };

  return { updateConfig, openSaveConfigModal, closeSaveConfigModal, updateActiveConfigurationId, updateSingleViewType };
};
