import { useDispatch } from 'react-redux';
import { ViewConfigurationAction } from '../../redux/actionTypes';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Selector for the Config store
 */
const selectViewConfigurationMode = (state) => state.viewConfigurationReducer;

/**
 * Custom hook for retrieving the cross hair store
 * @returns selector hook for retrieving crosshair store
 */
export const useViewConfigurationStore = () => {
  return useSelector(selectViewConfigurationMode);
};

/**
 * Custom hook for dispatching actions to update crosshair flags
 */

export const useViewConfigurationDispatch = () => {
  const dispatch = useDispatch();

  // callback to update the config store
  const updateViewConfiguration = (payload) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_VIEWCONFIGURATION,
      payload: { ...payload },
    });
  };

  // callback to update the SaveConfig modal to open
  const updateNumberOfAircrafts = (numberOfAircrafts) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_NUMBEROFAIRCRAFTS,
      payload: { numberOfAircrafts: numberOfAircrafts },
    });
  };

  // callback to update the SaveConfig modal to closed
  const updateHoursBefore = (hoursBefore) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_HOURSBEFORE,
      payload: { hoursBefore: hoursBefore },
    });
  };

  const updateHoursAfter = (hoursAfter) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_HOURSAFTER,
      payload: { hoursAfter: hoursAfter },
    });
  };

  const updateTimelineHours = (timelineHours) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_TIMELINEHOURS,
      payload: { timelineHours: timelineHours },
    });
  };

  const updateViewScalingData = (hoursBefore, hoursAfter, numberOfAircrafts) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_VIEWCONFIGURATION,
      payload: { hoursBefore: hoursBefore, hoursAfter: hoursAfter, numberOfAircrafts: numberOfAircrafts },
    });
  };

  const updatePuckSize = (puckSize) => {
    dispatch({
      type: ViewConfigurationAction.UPDATE_PUCKSIZE,
      payload: { puckSize: puckSize },
    });
  };

  return {
    updateViewConfiguration,
    updateTimelineHours,
    updateNumberOfAircrafts,
    updateHoursBefore,
    updateHoursAfter,
    updatePuckSize,
    updateViewScalingData,
  };
};
