import { convertRemToPixels } from '../../../lib/utils';
import { PuckSize, GanttScale } from '../../../lib/constants';

const getRightPuckSize = (ganttRowHeight) => {
  if (ganttRowHeight > convertRemToPixels(GanttScale.xlarge.puckHeight) * 1.5) {
    return PuckSize.X_LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.large.puckHeight) * 1.4) {
    return PuckSize.LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.medium.puckHeight) * 1.3) {
    return PuckSize.MEDIUM;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.small.puckHeight) * 1.2) {
    return PuckSize.SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xsmall.puckHeight) * 1.1) {
    return PuckSize.X_SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xxsmall.puckHeight)) {
    return PuckSize.XX_SMALL;
  } else {
    return PuckSize.XXX_SMALL;
  }
};

export default getRightPuckSize;
