import { ViewConfigurationAction } from '../actionTypes';
import { SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';

// initial state values
const initialState = {
  puckSize: 'large',
  timelineHours: 48,
  numberOfAircrafts: 45,
  hoursAfter: 32,
  hoursBefore: 4,
};

const getInitialState = () => {
  const savedViewConfigurationState = getSessionStorageItem(SessionStorageKeys.VIEW_CONFIGURATION);

  if (savedViewConfigurationState) {
    return savedViewConfigurationState;
  }

  return initialState;
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const viewConfigurationReducer = (state = getInitialState(), action) => {
  // graduate to SWITCH statement when there are more actiontypes available for this reducer
  switch (action.type) {
    case ViewConfigurationAction.UPDATE_VIEWCONFIGURATION: {
      if (
        action?.payload?.puckSize === initialState.puckSize &&
        action?.payload?.timelineHours === initialState.timelineHours &&
        action?.payload?.numberOfAircrafts === initialState.numberOfAircrafts &&
        action?.payload?.hoursAfter === initialState.hoursAfter &&
        action?.payload?.hoursBefore === initialState.hoursBefore
      ) {
        return state;
      }
      return {
        ...state,
        ...action?.payload,
      };
    }
    case ViewConfigurationAction.UPDATE_TIMELINEHOURS:
      return {
        ...state,
        timelineHours: action?.payload?.timelineHours ?? initialState.timelineHours,
      };
    case ViewConfigurationAction.UPDATE_NUMBEROFAIRCRAFTS:
      return {
        ...state,
        numberOfAircrafts: action?.payload?.numberOfAircrafts ?? initialState.numberOfAircrafts,
      };
    case ViewConfigurationAction.UPDATE_HOURSAFTER:
      return {
        ...state,
        hoursAfter: action?.payload?.hoursAfter ?? initialState.hoursAfter,
      };
    case ViewConfigurationAction.UPDATE_HOURSBEFORE:
      return {
        ...state,
        hoursBefore: action?.payload?.hoursBefore ?? initialState.hoursBefore,
      };
    case ViewConfigurationAction.UPDATE_PUCKSIZE:
      return {
        ...state,
        puckSize: action?.payload?.puckSize ?? initialState.puckSize,
      };
    default:
      return state;
  }
};

export default viewConfigurationReducer;
