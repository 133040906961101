import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import {
  useRoleAssignmentContext,
  useActiveRoleAssignment,
} from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';
import { useSwapModeStore, useSwapModeDispatch } from '../useSwapModeStore/useSwapModeStore';
import { useGanttConfigQuery } from '../useAirTrakManagerQuery/useAirTrakManagerQuery';
import { useFilterSearchDatesStore, useFilterStore } from '../useFilterStore/useFilterStore';
import { useHideStore } from '../useHideCancelledFlightsStore/useHideCancelledFlightsStore';
import { useSortFilterStore } from '../useSortFilterStore/useSortFilterStore';
import { useUserContext } from '../../contexts/UserContext/UserContext';
import {
  getSwapModeGanttConfig,
  getHideCancelledFlightsGanttConfig,
  getFilteredGanttConfig,
  getFlightPuckDataByKey,
  createGanttConfigQueryData,
  getSortedGanttConfig,
} from './utils/utils';
import {
  useSelectedItemDispatch,
  useSelectedItemStoreWithEqualComparator,
} from '../useSelectedItemStore/useSelectedItemStore';

/**
 * Returns gantt config for rendering the gantt. It includes flights that are in progress of swapping.
 * @param {boolean} enabled - Enable/disable the query. Default is true. If false, it will return undefined.
 * @returns
 */
export const useGanttConfig = (enabled = true) => {
  const { isSwapModeActive, swapFlightLegs, disableSwapSubmit } = useSwapModeStore();
  const { removeFlightLeg } = useSwapModeDispatch();
  const { showFeature } = useFeatureFlag();
  const showSwapFeature = showFeature(Treatment.FLIGHT_SWAP);
  const { hideCancelledFlights } = useHideStore();
  const { sortFilter } = useSortFilterStore();
  const { filter } = useFilterStore();
  const { activeAssignments } = useActiveRoleAssignment();
  const selectedFlightDetails = useSelectedItemStoreWithEqualComparator(isEqual);
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();
  const [ganttConfig, setGanttConfig] = useState({ loading: true, ganttConfig: {}, errors: {} });
  // Get initial gantt config from AirTrakManager
  const { loading, ganttConfig: initialGanttConfig, errors } = useAirtrakManagerGanttConfig();

  useEffect(() => {
    if (initialGanttConfig) {
      let ganttConfig = initialGanttConfig;

      if (sortFilter) {
        ganttConfig = getSortedGanttConfig(ganttConfig, sortFilter);
      }
      // Apply hide canceled flights toggle
      if (hideCancelledFlights) {
        ganttConfig = getHideCancelledFlightsGanttConfig(ganttConfig);
      }
      ganttConfig = getFilteredGanttConfig(ganttConfig, filter, activeAssignments);
      if (selectedFlightDetails?.data?.flightLegKey != null) {
        let selectedPuckNew = getFlightPuckDataByKey(
          ganttConfig,
          selectedFlightDetails.data.flightLegKey,
          selectedFlightDetails.data.aircraft,
        );
        if (
          selectedPuckNew != null &&
          Object.keys(selectedPuckNew).length &&
          !isEqual(selectedPuckNew, selectedFlightDetails.data)
        ) {
          const newFlightData = { ...selectedPuckNew };
          const newFlightDetails = {
            data: newFlightData,
            isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
            isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
          };
          updateSelectedFlightDetails(newFlightDetails);
        }
      }
      // Apply swap mode
      if (showSwapFeature && isSwapModeActive && swapFlightLegs.length) {
        ganttConfig = getSwapModeGanttConfig(ganttConfig, swapFlightLegs, removeFlightLeg, disableSwapSubmit);
      }
      setGanttConfig({ loading, ganttConfig, errors });
    }
  }, [
    initialGanttConfig,
    hideCancelledFlights,
    filter,
    activeAssignments,
    isSwapModeActive,
    swapFlightLegs,
    showSwapFeature,
    sortFilter,
  ]);

  if (!enabled) {
    return;
  }

  return ganttConfig;
};

/**
 * Returns gantt config from AirTrakManager
 * @returns - gantt config
 */
const useAirtrakManagerGanttConfig = () => {
  const { startDate, endDate } = useFilterSearchDatesStore();
  const { roleAssignments } = useRoleAssignmentContext();
  const userContext = useUserContext();
  const [ganttConfigQueryData, setGanttConfigQueryData] = useState({ loading: true, ganttConfig: {}, errors: {} });

  const payload = {
    employeeID: userContext.state.employeeID,
    startDateUTC: startDate?.isValid() ? startDate?.toISOString() : startDate,
    endDateUTC: endDate?.isValid() ? endDate?.toISOString() : endDate,
    roleKey: roleAssignments.role.roleID,
    operatingAirline: roleAssignments.role.operatingAirline,
  };

  // TODO remove enabled = true parameter
  const { isLoading, isSuccess, data } = useGanttConfigQuery(payload, true);

  useEffect(() => {
    setGanttConfigQueryData(createGanttConfigQueryData(isLoading, isSuccess, data));
  }, [isLoading, isSuccess, data]);

  return ganttConfigQueryData;
};
