import React from 'react';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import { useAircraftCrewMembers } from '../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import CollapsibleRegion from '../../../../Shared/CollapsibleRegion/CollapsibleRegion';
import TabSkeletonSection from '../../../FlightDetailPane/TabSkeletonSection/TabSkeletonSection';
import { DateFormat } from '../../../../../lib/constants';
import CrewTables from './CrewTables/CrewTables';
import DeadheadCrewTables from './DeadheadCrewTables/DeadheadCrewTables';
import FlightInfoTabs from './FlightInfoTabs/FlightInfoTabs';
import PropTypes from 'prop-types';
import './SummaryCrewTab.css';

const SummaryCrewTabContainer = ({ children }) => {
  return <div className="summary-crew-tab-container">{children}</div>;
};

const CrewInfo = ({ currentFlight, nextFlight, tripDetails, airline, scheduledOperatingDate }) => {
  const hasDeadheadsData = currentFlight.deadHeadCrewTrips?.length > 0 || nextFlight.deadHeadCrewTrips?.length > 0;

  const [expandCollapsibleRegion, setExpandCollapsibleRegion] = React.useState(hasDeadheadsData);

  return (
    <>
      <CrewTables flightCrew={currentFlight} nextFlightCrew={nextFlight} />

      <div className="flightinfo-deadhead-wrapper">
        <CollapsibleRegion
          text="DEADHEADS"
          disabled={!hasDeadheadsData}
          expand={expandCollapsibleRegion}
          onToggle={setExpandCollapsibleRegion}
        >
          <DeadheadCrewTables flightCrew={currentFlight} nextFlightCrew={nextFlight} />
        </CollapsibleRegion>

        <FlightInfoTabs
          isDeadheadsExpanded={expandCollapsibleRegion}
          tripDetails={tripDetails}
          airline={airline}
          scheduledOperatingDate={scheduledOperatingDate}
        />
      </div>
    </>
  );
};

const SummaryCrewTab = ({ selectedItem }) => {
  let selectedFlight = selectedItem.data;
  const operatingDate = formatDateTime(selectedFlight?.departureDate, DateFormat.DEFAULT);

  const { isLoading: loadingCrewMembers, data: crewMembers } = useAircraftCrewMembers({
    airline: selectedFlight?.airline,
    origin: selectedFlight?.orig,
    destination: selectedFlight?.dest,
    flightNumber: selectedFlight?.flightNumber,
    departureCount: selectedFlight?.departureCount,
    scheduledOperatingDate: operatingDate,
    aircraft: selectedFlight.aircraft,
  });

  if (!['AS', 'QX'].includes(selectedFlight?.airline)) {
    return <SummaryCrewTabContainer>No crew information available for this flight.</SummaryCrewTabContainer>;
  }

  if (loadingCrewMembers) {
    return (
      <SummaryCrewTabContainer>
        <TabSkeletonSection />
      </SummaryCrewTabContainer>
    );
  }

  const currentFlight = crewMembers?.crewOnFlights?.currentFlight;
  const nextFlight = crewMembers?.crewOnFlights?.nextFlight;
  const tripDetails = crewMembers?.crewOnFlights?.tripDetails;
  const crewInfoAvailable =
    currentFlight?.crewTrips?.length ||
    currentFlight?.deadHeadCrewTrips?.length ||
    nextFlight?.crewTrips?.length ||
    nextFlight?.deadHeadCrewTrips?.length;

  if (!crewInfoAvailable) {
    return <SummaryCrewTabContainer>Failed to retrieve Crew data. Please try again.</SummaryCrewTabContainer>;
  }

  return (
    <SummaryCrewTabContainer>
      <CrewInfo
        currentFlight={currentFlight}
        nextFlight={nextFlight}
        tripDetails={tripDetails}
        airline={selectedFlight?.airline}
        scheduledOperatingDate={operatingDate}
      />
    </SummaryCrewTabContainer>
  );
};

SummaryCrewTabContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

CrewInfo.propTypes = {
  currentFlight: PropTypes.object.isRequired,
  nextFlight: PropTypes.object.isRequired,
  tripDetails: PropTypes.object.isRequired,
  airline: PropTypes.string.isRequired,
  scheduledOperatingDate: PropTypes.any.isRequired,
};

SummaryCrewTab.propTypes = {
  selectedItem: PropTypes.shape({
    data: PropTypes.shape({
      airline: PropTypes.string,
      orig: PropTypes.string,
      dest: PropTypes.string,
      flightNumber: PropTypes.string,
      departureCount: PropTypes.string,
      departureDate: PropTypes.string,
      aircraft: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withAppInsightsTracking(SummaryCrewTab);
