import { SwapModeActionKeys } from '../actionTypes';

// intial state values
const initialState = {
  isSwapModeActive: false,
  latestSwappedFlightLegs: [],
  swapFlightLegs: [],
  currentSwapFlightLegs: [],
  futureSwapFlightLegs: [],
  swapFlightLegsHistory: [],
  futureSwapFlightLegsHistory: [],
  isSwapSelected: false,
  disableSwapSubmit: false,
};

/**
 *
 * @param {array} flightLegs - list of flight legs to filter with swapFlightLeg
 * @param {Object} swapFlightLeg - swapping flight leg object
 * @param {string} newAircraftRegistration - moving AircraftRegistration number
 * @returns filtered swapFlightLegs
 */
const updateSwapFlightLegs = (flightLegs, swapFlightLeg, newAircraftRegistration) => {
  let flightLegToBeSwapped = { ...swapFlightLeg };

  flightLegToBeSwapped.swapAircraftRegistration = newAircraftRegistration;

  // Filter swapFlightLeg from flightLegs List
  const filteredFlightLegs = flightLegs?.filter((f) => {
    const isSwapFlightLegAvailable =
      f?.flightNumber === flightLegToBeSwapped?.flightNumber &&
      f?.scheduledOperatingDateUTC === flightLegToBeSwapped?.scheduledOperatingDateUTC &&
      f?.actualOrigin === flightLegToBeSwapped?.actualOrigin &&
      f?.actualDestination === flightLegToBeSwapped?.actualDestination &&
      f?.scheduledOrigin === flightLegToBeSwapped?.scheduledOrigin &&
      f?.scheduledDestination === flightLegToBeSwapped?.scheduledDestination &&
      f?.departureCount === flightLegToBeSwapped?.departureCount;

    if (isSwapFlightLegAvailable) {
      // Gantt config updates puck aircraftRegistration to swapped flight
      // Need this to maintain original aircraftRegistration
      flightLegToBeSwapped.aircraftRegistration = f?.aircraftRegistration;
    }

    return !isSwapFlightLegAvailable;
  });

  // This occurs when swapping puck back to original aircraftRegistration
  // So only return filtered swap store
  if (flightLegToBeSwapped.swapAircraftRegistration === flightLegToBeSwapped.aircraftRegistration) {
    return [...filteredFlightLegs];
    // This occurs when swapping to a different aircraftRegistration
    // So return filtered swap store with updated swapAircraftRegistration
  } else if (filteredFlightLegs?.length > 0) {
    return [...filteredFlightLegs, flightLegToBeSwapped];
    // No other flightLegs in swap store, so return flightLegToBeSwapped
  } else {
    return [flightLegToBeSwapped];
  }
};

/**
 * Given the previous state and action payload, returns the new state for the Swap Mode
 * @returns the initial state for the SWapMode store
 */
const swapModeReducer = (state = initialState, action) => {
  const { disableSwapSubmit, flightData, aircraftRegistration = '' } = action.payload || [];

  const {
    swapFlightLegs = [],
    swapFlightLegsHistory = [],
    currentSwapFlightLegs = [],
    futureSwapFlightLegs = [],
    futureSwapFlightLegsHistory = [],
  } = state;

  switch (action.type) {
    case SwapModeActionKeys.ENABLE_SWAP_MODE:
      return {
        ...state,
        isSwapModeActive: true,
      };
    case SwapModeActionKeys.MOVE_FLIGHT:
      const updatedFlightLegs = flightData.reduce((acc, flight) => {
        const updatedFlightLeg = updateSwapFlightLegs(acc, flight, aircraftRegistration);
        return updatedFlightLeg;
      }, swapFlightLegs);

      if (updatedFlightLegs?.length > 0) {
        swapFlightLegsHistory.push([...updatedFlightLegs]);
      } else if (
        flightData?.length > 0 &&
        flightData[0]?.aircraftRegistration !== aircraftRegistration &&
        updatedFlightLegs?.length === 0
      ) {
        swapFlightLegsHistory.push([...flightData]);
      }
      const currentMoveFlightLegs =
        swapFlightLegsHistory && swapFlightLegsHistory[swapFlightLegsHistory?.length - 1] > 0
          ? swapFlightLegsHistory[swapFlightLegsHistory?.length - 1]
          : updatedFlightLegs;

      const updatedNewlySwapFlightLegs = flightData.reduce((acc, flight) => {
        const updatedFlightLeg = updateSwapFlightLegs(acc, flight, aircraftRegistration);
        return updatedFlightLeg;
      }, []);

      return {
        ...state,
        swapFlightLegs: currentMoveFlightLegs,
        latestSwappedFlightLegs: updatedNewlySwapFlightLegs,
        futureSwapFlightLegs: [],
        isSwapSelected: true,
        disableSwapSubmit: false,
      };
    // New Action to swap a flight line with another. Single Swap will still use SWAP_FLIGHT action
    case SwapModeActionKeys.SWAP_FLIGHT_LINE:
      const dragFlightLine = flightData[0];
      const dropFlightLine = flightData[1];

      const dragFlightLegs = dragFlightLine.reduce((acc, flight) => {
        const swapDragFlightLeg = updateSwapFlightLegs(acc, flight, dropFlightLine[0].aircraftRegistration);
        return swapDragFlightLeg;
      }, swapFlightLegs);

      const dropFlightLegs = dropFlightLine.reduce((acc, flight) => {
        const swapDropFlightLeg = updateSwapFlightLegs(acc, flight, dragFlightLine[0].aircraftRegistration);
        return swapDropFlightLeg;
      }, dragFlightLegs);

      if (dropFlightLegs?.length > 0) {
        swapFlightLegsHistory.push([...dropFlightLegs]);
      }
      const currentSwapFlightLineLegs =
        swapFlightLegsHistory[swapFlightLegsHistory?.length - 1] > 0
          ? swapFlightLegsHistory[swapFlightLegsHistory?.length - 1]
          : dropFlightLegs;

      const dragNewightLegs = dragFlightLine.reduce((acc, flight) => {
        const swapDragFlightLeg = updateSwapFlightLegs(acc, flight, dropFlightLine[0].aircraftRegistration);
        return swapDragFlightLeg;
      }, []);

      const dropNewFlightLegs = dropFlightLine.reduce((acc, flight) => {
        const swapDropFlightLeg = updateSwapFlightLegs(acc, flight, dragFlightLine[0].aircraftRegistration);
        return swapDropFlightLeg;
      }, dragNewightLegs);

      return {
        ...state,
        swapFlightLegs: currentSwapFlightLineLegs,
        latestSwappedFlightLegs: dropNewFlightLegs,
        futureSwapFlightLegs: [],
        isSwapSelected: true,
        disableSwapSubmit: false,
      };

    case SwapModeActionKeys.SAVED_SWAP_FLIGHT:
      const savedSwapFlights = swapFlightLegs.filter(
        (x) =>
          flightData.findIndex(
            (y) =>
              y?.flightNumber === x?.flightNumber &&
              y?.scheduledOperatingDateUTC === x?.scheduledOperatingDateUTC &&
              y?.origin === x?.origin &&
              y?.destination === x?.destination &&
              y?.departureCount === x?.departureCount &&
              y?.operatingAirline === x?.operatingAirline, // Include operatingAirline check
          ) === -1,
      );

      if (savedSwapFlights?.length > 0) {
        swapFlightLegsHistory.push([...savedSwapFlights]);
      }
      const currentSavedFlightLegs =
        swapFlightLegsHistory[swapFlightLegsHistory?.length - 1] > 0
          ? swapFlightLegsHistory[swapFlightLegsHistory?.length - 1]
          : savedSwapFlights;

      return {
        ...state,
        swapFlightLegs: currentSavedFlightLegs,
        latestSwappedFlightLegs: [],
        futureSwapFlightLegs: [],
        isSwapSelected: true,
        disableSwapSubmit: false,
      };
    case SwapModeActionKeys.DISCARD_FLIGHT:
      const discardSwapFlightLegsHistory = swapFlightLegsHistory.slice(0, -1);
      const lastElementIndex = discardSwapFlightLegsHistory.length - 1;
      const updateDiscardSwapFlightLegs = discardSwapFlightLegsHistory[lastElementIndex] || [];

      return {
        ...state,
        swapFlightLegs: updateDiscardSwapFlightLegs,
        latestSwappedFlightLegs: [],
        swapFlightLegsHistory: discardSwapFlightLegsHistory,
        futureSwapFlightLegs: futureSwapFlightLegsHistory,
        isSwapSelected: false,
      };
    case SwapModeActionKeys.UNDO_FLIGHT:
      const prevUndoItem = swapFlightLegsHistory[swapFlightLegsHistory.length - 1] || [];
      const undoSwapFlightLegsHistory = swapFlightLegsHistory.slice(0, -1);
      const updateUndoSwapFlightLegs = undoSwapFlightLegsHistory[undoSwapFlightLegsHistory.length - 1] || [];

      const prependItem =
        currentSwapFlightLegs?.length > 0 ? currentSwapFlightLegs : prevUndoItem.length > 0 ? prevUndoItem : [];
      const updatedFutureSwapFlightLegs = [prependItem, ...futureSwapFlightLegs];
      const updatedFutureSwapFlightLegsHistory = [prependItem, ...futureSwapFlightLegsHistory];

      return {
        ...state,
        swapFlightLegs: updateUndoSwapFlightLegs,
        latestSwappedFlightLegs: [],
        swapFlightLegsHistory: undoSwapFlightLegsHistory,
        currentSwapFlightLegs: prevUndoItem,
        futureSwapFlightLegs: updatedFutureSwapFlightLegs,
        futureSwapFlightLegsHistory: updatedFutureSwapFlightLegsHistory,
        isSwapSelected: false,
      };
    case SwapModeActionKeys.REDO_FLIGHT:
      const prevRedoItem =
        futureSwapFlightLegs?.length > 0 && futureSwapFlightLegs[0]?.length > 0 ? futureSwapFlightLegs[0] : [];
      const newRedoItems = futureSwapFlightLegs?.length > 0 ? futureSwapFlightLegs.slice(1) : [];

      const currentFlightLegs = currentSwapFlightLegs?.length > 0 ? currentSwapFlightLegs : [];
      const updateFlightHistoryData = [...swapFlightLegsHistory, currentSwapFlightLegs];

      return {
        ...state,
        swapFlightLegs: [...currentFlightLegs],
        latestSwappedFlightLegs: [],
        swapFlightLegsHistory:
          updateFlightHistoryData.length > 0 && updateFlightHistoryData[updateFlightHistoryData.length - 1]
            ? updateFlightHistoryData
            : [],
        currentSwapFlightLegs: prevRedoItem,
        futureSwapFlightLegs: newRedoItems,
        futureSwapFlightLegsHistory: newRedoItems,
        isSwapSelected: false,
      };
    case SwapModeActionKeys.CLEAR_SWAP_MODE:
      return {
        ...initialState,
        swapFlightLegsHistory: [],
      };
    case SwapModeActionKeys.CLEAR_SWAP_DATA:
      return {
        ...initialState,
        isSwapModeActive: true,
        swapFlightLegs: state.swapFlightLegs,
        disableSwapSubmit: disableSwapSubmit,
        swapFlightLegsHistory: [],
      };
    case SwapModeActionKeys.REMOVE_FLIGHT:
      const updatedSwapFlightLegs = swapFlightLegs.filter(
        (swapLeg) =>
          flightData?.length &&
          !flightData.some(
            (removeLeg) =>
              removeLeg?.flightNumber === swapLeg?.flightNumber &&
              removeLeg?.scheduledOperatingDateTimeUTC === swapLeg?.scheduledOperatingDateTimeUTC &&
              removeLeg?.actualOrigin === swapLeg?.actualOrigin &&
              removeLeg?.actualDestination === swapLeg?.actualDestination &&
              removeLeg?.departureCount === swapLeg?.departureCount &&
              removeLeg?.operatingAirline === swapLeg?.operatingAirline,
          ),
      );

      return {
        ...initialState,
        isSwapModeActive: true,
        swapFlightLegs: updatedSwapFlightLegs,
        disableSwapSubmit: false,
        swapFlightLegsHistory: state.swapFlightLegsHistory,
        futureSwapFlightLegs: state.futureSwapFlightLegs,
      };
    default:
      return state;
  }
};

export default swapModeReducer;
