import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FlightInfoTable from '../FlightInfoTable/FlightInfoTable';
import { Grid } from '@material-ui/core';
import { useGetTripDetails } from '../../../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';

const FlightInfoTables = React.memo(({ flightInfoData, date, tripNumbers, airline, columnNames }) => {
  const [tripInfo, setTripInfo] = useState([]);

  const { isLoading: loadingTripDetails, data: tripDetails } = useGetTripDetails({
    airline: airline,
    tripDate: date,
    tripNumbers: tripNumbers,
  });

  useEffect(() => {
    if (tripInfo.length == 0 && flightInfoData != null) {
      setTripInfo(flightInfoData);
    }
  }, []);

  useEffect(() => {
    if (loadingTripDetails) {
      return;
    }

    if (tripDetails != null) {
      console.log('updating flightInfoDataToDisplay');
      console.log('tripDetails', tripDetails);
      setTripInfo(tripDetails.tripFlights);
    }
  }, [loadingTripDetails]);

  return (
    <Grid container spacing={2} style={{ padding: '1rem' }}>
      {!loadingTripDetails && tripInfo.length == 0 && <div>Failed to retrieve Crew data. Please try again.</div>}
      {!loadingTripDetails &&
        tripInfo.length > 0 &&
        Array.from(tripInfo).map((flightInfo, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
            <FlightInfoTable
              flightInfo={flightInfo.tripFlightDetails}
              trip={flightInfo.tripNumber}
              date={flightInfo.tripDate}
              columnNames={columnNames}
            />
          </Grid>
        ))}
    </Grid>
  );
});
FlightInfoTables.propTypes = {
  date: PropTypes.object,
  tripNumbers: PropTypes.array,
  airline: PropTypes.string,
  columnNames: PropTypes.array,
  flightInfoData: PropTypes.array,
};
export default memo(FlightInfoTables);
