import { getTimeDifference } from '../../../lib/dateTimeUtils';

import './PuckContainer.css';
import { Treatment, GanttScale } from '../../../lib/constants';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import PropTypes from 'prop-types';
import { convertRemToPixels } from '../../../lib/utils';
import { useSelectedItemDispatch } from '../../../hooks/useSelectedItemStore/useSelectedItemStore';

/**
 * A PuckContainer is responsible for aligning a given puck's left positioning
 * @param {Object} props
 * @param {number} props.totalRows

 * - props.ganttStartDateTime - when the gantt timeline starts
 * - props.puckStartDateTime - when the puck starts
 * - props.customCSSTag - custom CSS tag for container
 * - props.children - the puck to align
 * @returns
 */
const PuckContainer = ({
  ganttStartDateTime,
  puckStartDateTime,
  customCSSTag = '',
  dataCyTag = 'puck-container',
  children,
  totalRows,
  enhancedGanttRowHeight,
  data,
}) => {
  const { showFeature } = useFeatureFlag();
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const { useIsSelected } = useSelectedItemDispatch();

  const timeFromStart = getTimeDifference(ganttStartDateTime, puckStartDateTime, 'minute');
  const isSelected = useIsSelected(data, 'standby');
  const containerStyle = !enableEnhancedScalingFlag
    ? {
        position: 'absolute',
        left: `calc(${timeFromStart} * var(--time-scale-minute))`,
        top: totalRows === 1 ? '30%' : '',
        height: '40%',
        lineHeight: '40%',
      }
    : {
        position: 'absolute',
        left: `calc(${timeFromStart} * var(--time-scale-minute))`,
        top: totalRows === 1 ? '50%' : '',
        transform: totalRows === 1 && 'translateY(-50%)',
        marginTop: totalRows > 1 && 'var(--puck-margin-top)',
        height:
          totalRows > 1 && enhancedGanttRowHeight < convertRemToPixels(GanttScale.xsmall.puckHeight)
            ? `${enhancedGanttRowHeight}px`
            : 'var(--puck-height)',
        lineHeight:
          totalRows > 1 && enhancedGanttRowHeight < convertRemToPixels(GanttScale.xsmall.puckHeight)
            ? `${enhancedGanttRowHeight}px`
            : 'var(--puck-line-height)',
        width: 'auto',
        zIndex: isSelected ? '99' : '',
      };

  return (
    <div
      className={`puck-container${!!customCSSTag ? ` ${customCSSTag}` : ''}`}
      style={containerStyle}
      data-cy={dataCyTag}
    >
      {children}
    </div>
  );
};

export default PuckContainer;

PuckContainer.propTypes = {
  totalRows: PropTypes.number.isRequired,
  enhancedGanttRowHeight: PropTypes.number.isRequired,
};
