import { useDispatch, useSelector } from 'react-redux';
import { RootAction } from '../../redux/actionTypes';

/** Selector for the Root store  */
const selectMultipleViewsRoot = (state) => state.multipleViewsRootReducer;

/**
 * Custom hook for retrieving the root store
 * @returns selector hook for retrieving root store
 */
export const useMultipleViewsRootStore = () => {
  const rootStore = useSelector(selectMultipleViewsRoot);
  return rootStore;
};

/**
 * Custom hook for dispatching actions to update root state
 */
export const useMultipleViewsRootDispatch = () => {
  const dispatch = useDispatch();

  //Update the root state with the payload provided in the argument
  const updateMultipleViewsRootState = (payload) => {
    dispatch({
      type: RootAction.UPDATE_ROOTSTATE,
      payload: { rootState: payload },
    });
  };

  return { updateMultipleViewsRootState };
};
