import PropTypes from 'prop-types';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InfoBlock from '../../../../FlightDetailPane/FlightTab/InfoBlock/InfoBlock';
import MiniInfoBlock from '../../../../FlightDetailPane/FlightTab/MiniInfoBlock/MiniInfoBlock';
import { withAppInsightsTracking } from '../../../../../../services/appInsightsFactory/appInsightsFactory';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  firstRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '4.8rem',
    },
  },
  secondRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '9.933rem',
    },
  },
  thirdRowHeight: {
    [theme.breakpoints.up('lg')]: {
      height: '8.533rem',
    },
    [theme.breakpoints.only('xl')]: {
      height: 'auto',
    },
  },
}));

/** returns the summary block of the flight tab
 * @param {Object} flightDetails - details of the flight
 * @returns FlightTabSummary */
const FlightTabSummary = ({ flightDetails, isSeatCountDataErr, isDispatchDeskDataErr }) => {
  const classes = useStyles();

  const equipment = flightDetails?.summary?.equipment?.replace(/.*\(([^)]+)\).*/, '$1') || '';

  return (
    <div data-cy="flight-tab-summary-block">
      <Grid container item spacing={1} className={classes.firstRowHeight}>
        <Grid item xs={6}>
          <div id="flight-tab-header-container" data-cy="flight-tab-header" className="flex-row">
            <div className="flight-header">SUMMARY</div>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1} className={classes.secondRowHeight}>
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="departure-block">
            <InfoBlock label="Departure">
              <MiniInfoBlock
                station={flightDetails?.departure?.station}
                actualTime={flightDetails?.summary?.departureTimeUTC ?? 0}
                scheduledTime={flightDetails?.summary?.arrivalTimeUTC ?? 0}
                delayTime={flightDetails?.summary?.departureDelayMinutes}
                dataCyTag="departure-time-display"
              />
            </InfoBlock>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="arrival-block">
            <InfoBlock label="Arrival">
              <MiniInfoBlock
                station={flightDetails?.arrival?.station}
                actualTime={flightDetails?.summary?.arrivalTimeUTC ?? 0}
                scheduledTime={flightDetails?.summary?.departureTimeUTC ?? 0}
                delayTime={flightDetails?.summary?.arrivalDelayMinutes}
                dataCyTag="arrival-time-display"
              />
            </InfoBlock>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1} className={classes.thirdRowHeight}>
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="passenger-count-block">
            <InfoBlock label="Passenger Count">
              {isSeatCountDataErr ? 'Err' : flightDetails?.summary?.passengerCount ?? 'N/A'}
            </InfoBlock>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="equipment-block">
            <InfoBlock label="Equipment">{equipment ?? 'N/A'}</InfoBlock>
          </div>
        </Grid>
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <div className="flex-col-left" data-cy="dispatcher-block">
            <InfoBlock label="Dispatcher" dataCyTag="dispatcher">
              {isDispatchDeskDataErr ? 'Err' : flightDetails?.summary?.dispatcher ?? 'N/A'}
            </InfoBlock>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className="flex-col-right" data-cy="service-type-block">
            <InfoBlock label="Service Type" dataCyTag="service-type">
              {/* leave first letter uppercase, the rest lowercase */}
              {flightDetails?.summary?.serviceType?.charAt(0)}
              {flightDetails?.summary?.serviceType?.slice(1).toLowerCase() ?? 'N/A'}
            </InfoBlock>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
FlightTabSummary.propTypes = {
  flightDetails: PropTypes.object.isRequired,
};

export default withAppInsightsTracking(FlightTabSummary);
